
<template>
    <div class="result second-box">
        <Head :title="$t('mine.myauction')"  :show="true" :right="true">
           
            <van-popover
                v-model="showPopover"
                trigger="click"
                :actions="actions"
                @select="onSelect"
                placement="bottom-end"
                >
                    <template #reference>
                        <p class="phead" style="color:#fff">{{txt}}<van-icon :name="!showPopover?'arrow-down':'arrow-up'" class="pheadicon" /></p>
                    </template>
                </van-popover>
        </Head>
        <div class="main-box result-box">
            <div class="nav-main">
                <van-tabs :sticky="true" :animated="true" :swipeable="true" v-model="active" class="mytabs">
                    <van-tab  name="1"  title="Cloud Bid">
                        <MyAuctionList :changecbruleShow="changecbruleShow" :changeShareItem="changeShareItem" :refresh="refresh" :timeendaction="timeEndAction" :listadd="listadd" :changeshowttpay="changeshowttpay" :cb="true" :getRecordList="getRecordList"  :change="change" :list="list.filter(item=>item.active_id==active)" ></MyAuctionList>
                    </van-tab>
                    <van-tab  name="2"  title="Time Twister">
                        <MyAuctionList :changecbruleShow="changecbruleShow" :changeShareItem="changeShareItem" :refresh="refresh" :timeendaction="timeEndAction" :listadd="listadd" :changeshowttpay="changeshowttpay" :tt="true" :getRecordList="getRecordList" :change="change" :list="list.filter(item=>item.active_id==active)"></MyAuctionList>
                    </van-tab>
                </van-tabs>
            </div>
        </div>
    
    <!-- cb rule 显示  -->
        <van-overlay class="g-overlay" :show="cbruleShow" >
          <div class="cbrule-mask" :class="lang=='en'?'en-mask':''" @click.stop>
                <div class="top">
                    {{$t('good.func')}}
                </div>
                <div class="cbrule-main">
                    <p>
                    <span class="color-main">{{$t('rule.cb_rule_sk')}}</span> 
                    <span>{{$t('rule.cb_rule_sv')}}</span>
                    </p>
                    <p>
                        <span class="color-main">{{$t('rule.cb_rule_sk1')}}</span> 
                        <span>{{$t('rule.cb_rule_sv1')}}</span>
                    </p>
                    <p>
                        <span class="color-main">{{$t('rule.cb_rule_sk2')}}</span> 
                        <span>{{$t('rule.cb_rule_sv2')}}</span>
                    </p>
                    <p>
                        <span class="color-main">{{$t('rule.cb_rule_a4')}}</span>
                    </p>
                </div>
                <div class="overlay-btns" :class="lang=='cn'?'cn-mt':''">
                    <van-button class="surebtn btn-sure"  @click="cbruleShow=false">{{$t('add.well')}}</van-button>
                </div>
          </div>
        </van-overlay>
        
         <!-- 回收奖品 -->
        <van-overlay class="g-overlay" :show="repay" >
          <div class="repay-mask" @click.stop>
                <div class="top">
                    {{$t('mine.huishou')}}
                </div>
                <div class="title">{{$t('mine.is_need_huishou')}}</div>
                <p class="ptext">{{$t('mine.your_need_rate')}}<span v-if="thisinfo">{{thisinfo.sell_winning_rate * 100 | number_format}}%</span>,{{$t('mine.after_back')}}</p>
                <p class="ptext">{{$t('mine.you_get')}}<span  v-if="thisinfo">{{item.total_amount*(1-thisinfo.sell_winning_rate) | number_format }}PV</span></p>
                <div class="overlay-btns">
                    <van-button class="btn btn-cancel" @click="repay=false" >{{$t('mine.no')}}</van-button>
                    <van-button class="btn btn-sure"  @click="sellwinning()">{{$t('mine.yes')}}</van-button>
                </div>
          </div>
        </van-overlay>

        <!-- 我的竞拍记录  -->
        <!-- 1 60S  3 30 5 5s  -->
        <van-overlay class="my-overlay" :show="recordshow"    @click="recordshow = false">
            <div class="record-list" @click.stop>
                <ul class="gold-list">
                    <li class="li-item" v-for='(v,i) in recordList' :key="i" >
                        <!-- <p>{{i+1}}</p> -->
                        <p class="li-item-time">{{v.send_time}}</p>
                        <p class="li-item-sec">{{$t('mine.add')}}{{ parseInt(v.spentime) }}{{$t('mine.second')}}</p>
                        <p class="li-item-money">${{v.amount | number_format}}</p>
                    </li>
                    <van-divider  :style="{ color: '#999', borderColor: '#DF561C', padding: '0 16px' }">{{$t('auction.total')}} {{recordList.length}} {{$t('auction.no')}}{{$t('auction.record')}}</van-divider>
                    <!-- <van-divider>共 {{recordList.length}} 条记录</van-divider> -->
                </ul>
            </div>
        </van-overlay>


    <!-- 支付 输入二级密码   -->
     <van-popup class="login-popup pay-one money-pay" v-model="secshow" position="bottom"   duration="0.2" >
        <div class="login-popup-box">
            <div class="pop-top">
                <p>{{$t('login.confirm')}}{{$t('wallet.pay')}}</p>
                <van-icon name="cross" class="pop-close" @click="secshow=false" />
            </div>
            <van-form class="pay-form" ref="pinform" @submit="payttwinningorder">
                <p class="pay-account">{{$t('wallet.pay')}}:${{item.betprice | number_format}}</p>
                <van-field
                v-model="pin"
                :label="$t('login.second_pass')"
                :placeholder="$t('login.required_sec_pass')"
                :type="pt3"
                name="pin"
                :right-icon="pt3=='password'?'eye':'closed-eye'"
                @click-right-icon="changePassType3"
                :rules="[
                  { required: true, message: $t('login.required_sec_pass') },
                  { pattern:passPattern,message:$t('login.secpass_error'),trigger:'onBlur'}
                ]"
                >
                </van-field>
            </van-form>
            <div class="last-btns">
                <van-button class="the-btn btn-one" @click="secshow=false">{{$t('login.cancel')}}</van-button>
                <van-button class="the-btn btn-two" @click="launchForm">{{$t('login.confirm')}}</van-button>
            </div>

            <!-- <van-action-sheet v-model="show" :actions="actions" @select="onSelect" /> -->
        </div>
    </van-popup>

    <!-- 分享   -->
     <van-popup class="login-popup pay-one money-pay" v-model="shareshow" position="bottom"   duration="0.2" >
        <div class="login-popup-box">
            <div class="pop-top">
                <p>{{$t('login.confirm')}}{{$t('add.share')}}</p>
                <van-icon name="cross" class="pop-close" @click="shareshow=false" />
            </div>
            <div class="share-box ">
                <div 
                    class="share-li fb-share-button bounce" 
                    :data-href="$host+'app/index.html#/register?recommend_code='+thisinfo.recommend_code" 
                    data-layout="button" 
                    data-size="large">
                    <a target="_blank" 
                    :href="'https://www.facebook.com/sharer/sharer.php?u='+$host+'app/index.html#/register?recommend_code='+thisinfo.recommend_code+'&amp;src=sdkpreparse'" 
                    class="fb-xfbml-parse-ignore">
                         <svg class="share-icon" aria-hidden="true">
                            <use xlink:href="#iconFACEBOOK"></use>
                        </svg>
                        <p>FaceBook</p>
                    </a></div>

                    <LineBtn :sharelink="$host+'app/index.html#/register?recommend_code='+thisinfo.recommend_code"/>
                <!-- <div class="share-li line-it-button bounce" 
                    data-lang="zh_Hant" data-type="share-b" data-ver="3"
                    :data-url="$host+'app/index.html#/register?recommend_code='+thisinfo.recommend_code" 
                    data-color="default" 
                    data-size="small" 
                    data-count="true" 
                >
                    <svg class="share-icon" aria-hidden="true">
                        <use xlink:href="#iconLineMessenger"></use>
                    </svg>
                    <p>Line Messenger</p>
        
                </div> -->
            </div>
        </div>
    </van-popup>
    
    </div>
</template>
<script>
import {myinfo,randomString} from "@/common"
import {passReg} from "@/common/validate"
import {Notify} from "vant"
import {mapState,mapMutations} from "vuex"
export default {
  name: "mine-myAuction",
  mixins:[myinfo],
  data() {
    return {
        cbruleShow:false,
        recordshow:false,
        repay:false,
        active: "1",
        list:[],
        flag:false,
        item:{},
        recordList:[],
        pt3:"password",
        secshow:false,
        shareshow:false,
        pin:'',
        passPattern:passReg,
        showPopover:false,
        txt:this.$t("common.all"),
        txt_num:-1,   // 全部
        actions: [
            { text: this.$t("common.all"), value: -1 },
            { text: this.$t("add.running"), value: 2 },
            { text: this.$t("add.opened"), value: 1 },
            { text: this.$t("add.has_win"), value: 3 },
            { text: this.$t("add.no_win"), value: 4 },
        ],
        oldlist:[],
        page:1,
        pageSize:50,
        total:0,
        thisinfo:JSON.parse(localStorage.getItem("dashboard")),
        lang:localStorage.getItem("langData") || 'th',
    };
  },
  computed:{
      ...mapState(['jiqigoodList','homewinnerList']),
  },
  activated(){
      this.getInitData();
  },
  watch:{
    // 重置支付密码
    secshow(v){
        this.pin = ""
        this.pt3 = "password"
    },
    homewinnerList(v){

        var obj = v[v.length-1]
        var data = {};
        if(obj['tt_winninginfo']){
            data = obj['tt_winninginfo']
        }else{
            data = obj['cb_winninginfo']
        }   
        for(let i in this.oldlist){
            let item = this.oldlist[i];
            if(item.good_id==data.good_id&&item.qishu==data.qishu){
                item = {...item,resultOut:true}
            }
            this.$set(this.oldlist,i,item)
        }    
        if(this.txt_num==-1){
            this.list = [...this.oldlist];
        }else if(this.txt_num==1){
            this.list = this.oldlist.filter(item=>item.is_end==1);
        }else if(this.txt_num==3){
            this.list = this.oldlist.filter(item=>item.isWinner==1);
        }else if(this.txt_num==4){
            this.list = this.oldlist.filter(item=>item.isWinner!=1);
        }else{
            this.list = this.oldlist.filter(item=>item.is_end!=1);
        }
    },
    jiqigoodList(v){
        var new_data = v[0];
        for(let i in this.oldlist){
            let item = this.oldlist[i];
            if(item.active_id==1){
                if(new_data['buy_cb']){
                    if(new_data['buy_cb'].qishu==item.qishu&&new_data['buy_cb'].good_id==item.good_id){
                        item = {
                            ...item,
                            join_num:item.join_num*1 + new_data['buy_cb'].total*1 
                        }
                    }
                }
            }
            if(item.active_id==2){
                if(new_data['buy_tt']){
                    if(new_data['buy_tt'].qishu==item.qishu&&new_data['buy_tt'].good_id==item.good_id){
                        console.log(new_data['buy_tt'])
                        item = {
                            ...item,
                            countdown:{
                                day:new_data['buy_tt'].day,
                                hours:new_data['buy_tt'].hours,
                                mins:new_data['buy_tt'].mins,
                                second:new_data['buy_tt'].second,
                            },
                            timeCount:parseInt(new_data['buy_tt'].day) * 24 * 3600 * 1000 + parseInt(new_data['buy_tt'].hours)  * 3600 * 1000 + parseInt(new_data['buy_tt'].mins)  *60 * 1000 + parseInt(new_data['buy_tt'].second)  * 1000
                        }
                    }
                }
            }
            this.$set(this.oldlist,i,item);
        }

        if(this.txt_num==-1){
            this.list = [...this.oldlist];
        }else if(this.txt_num==1){
            this.list = this.oldlist.filter(item=>item.is_end==1);
        }else if(this.txt_num==3){
            this.list = this.oldlist.filter(item=>item.isWinner==1);
        }else if(this.txt_num==4){
            this.list = this.oldlist.filter(item=>item.isWinner!=1);
        }else{
            this.list = this.oldlist.filter(item=>item.is_end!=1);
        }
    }
  },
  mounted() {
    //   this.getInitData();
  },    
  methods: {
      changecbruleShow(){
          this.cbruleShow = true;
      },
      // 分享    
      shareTo(){

      },
      shareToFb(){

      },
      changeShareItem(item){
          this.shareshow = true;
          this.item = item;
      },
      timeEndAction(val){
            for(let i in this.oldlist){
                let item = this.oldlist[i];
                if(val.good_id==item.good_id&&val.qishu==item.qishu){
                    item = {...item, timeend:true} 
                }
                this.$set(this.oldlist,i,item)  
            }
            if(this.txt_num==-1){
                this.list = [...this.oldlist];
            }else if(this.txt_num==1){
                this.list = this.oldlist.filter(item=>item.is_end==1);
            }else if(this.txt_num==3){
                this.list = this.oldlist.filter(item=>item.isWinner==1);
            }else if(this.txt_num==4){
                this.list = this.oldlist.filter(item=>item.isWinner!=1);
            }else{
                this.list = this.oldlist.filter(item=>item.is_end!=1);
            }
        },
        refresh(callback){
            this.page = 1;
            this.$ajax.graphistorylist({
                form_token:randomString(),
                page:this.page,
                pageSize:this.pageSize
            })
            .then(res=>{
                if(res.code==200){
                    // this.list = res.data.rstArr;
                    this.oldlist = res.data.rstArr;
                    this.total = res.data.tpage.total;
                    if(this.txt_num==-1){
                        this.list = [...this.oldlist];
                    }else if(this.txt_num==1){
                        this.list = this.oldlist.filter(item=>item.is_end==1);
                    }else if(this.txt_num==3){
                        this.list = this.oldlist.filter(item=>item.isWinner==1);
                    }else if(this.txt_num==4){
                        this.list = this.oldlist.filter(item=>item.isWinner!=1);
                    }else{
                        this.list = this.oldlist.filter(item=>item.is_end!=1);
                    }
                }
                callback()
            })
        },
        listadd(callback,cbend){
            if(this.page<Math.ceil(this.total/this.pageSize)){
                this.page++;
                this.$ajax.graphistorylist({
                    form_token:randomString(),
                    page:this.page,
                    pageSize:this.pageSize
                })
                .then(res=>{
                    if(res.code==200){
                        // this.list = this.list.concat(res.data.rstArr);
                        this.oldlist = this.oldlist.concat(res.data.rstArr);
                        this.total = res.data.tpage.total
                        if(this.txt_num==-1){
                            this.list = [...this.oldlist];
                        }else if(this.txt_num==1){
                            this.list = this.oldlist.filter(item=>item.is_end==1);
                        }else if(this.txt_num==3){
                            this.list = this.oldlist.filter(item=>item.isWinner==1);
                        }else if(this.txt_num==4){
                            this.list = this.oldlist.filter(item=>item.isWinner!=1);
                        }else{
                            this.list = this.oldlist.filter(item=>item.is_end!=1);
                        }
                        callback()
                    }
                })
                
            }else{
                cbend()
            }
        },
        onSelect(val){
           
            this.txt = val.text;
            this.txt_num = val.value;
            // 已经开奖  is_end == 1;
            // 进行中    is_end !== 1;    2
            if(val.value==-1){
                this.list = [...this.oldlist];
            }else if(val.value==1){
                this.list = this.oldlist.filter(item=>item.is_end==1);
            }else if(this.txt_num==3){
                this.list = this.oldlist.filter(item=>item.isWinner==1);
            }else if(this.txt_num==4){
                this.list = this.oldlist.filter(item=>item.isWinner!=1);
            }else{
                this.list = this.oldlist.filter(item=>item.is_end!=1);
            }
        },
        getInitData(){
            this.$ajax.graphistorylist({
                form_token:randomString(),
                page:this.page,
                pageSize:this.pageSize
            })
            .then(res=>{
                if(res.code==200){
                    this.list = res.data.rstArr;
                    this.oldlist = res.data.rstArr;
                    this.total = res.data.tpage.total
                }
            })
        },
        changeshowttpay(item){
           console.log(item);
           this.item = item;
           this.secshow = true;
        },
        // tt商品支付    
        payttwinningorder(){
            this.$ajax.payttwinningorder({
            form_token:randomString(),
            win_orderid:this.item.winning_orderid,
            pin:this.pin
            }).then(res=>{
                if(res.code==200){
                    var item = this.item;
                    this.oldlist = this.oldlist.map(v=>{
                        if(v.good_id==item.good_id&&v.active_id==2&&v.qishu==item.qishu){
                            v = {...v,is_pay:1}   // 已经支付
                        }
                        return v;
                    })
                    if(this.txt_num==-1){
                        this.list = [...this.oldlist];
                    }else if(this.txt_num==1){
                        this.list = this.oldlist.filter(item=>item.is_end==1);
                    }else if(this.txt_num==3){
                        this.list = this.oldlist.filter(item=>item.isWinner==1);
                    }else if(this.txt_num==4){
                        this.list = this.oldlist.filter(item=>item.isWinner!=1);
                    }else{
                        this.list = this.oldlist.filter(item=>item.is_end!=1);
                    }
                    
                    this.secshow = false;
                    this.item = {};
                    this.pin = ""
                    Notify({ type: 'success', message: res.msg });
                    // 后期需要修改 优化 
                    // this.getInitData();

                    
                }
            })
        },
        changePassType3(){
            this.pt3 = this.pt3 == "password"?"text":"password"
        },
        launchForm(){
        this.$refs.pinform.submit();
        },
        // 回收奖品  
      sellwinning(){
        this.$ajax.sellwinning({
            form_token:randomString(),
            win_orderid:this.item.winning_orderid,
        })
        .then(res=>{
            if(res.code==200){
                this.repay = false;
                this.oldlist = this.oldlist.map(v=>{
                    if(v.good_id==this.item.good_id&&v.qishu==this.item.qishu){
                        v = {...v,is_sell_winning:1}   // 已经支付
                    }
                    return v;
                })
                if(this.txt_num==-1){
                    this.list = [...this.oldlist];
                }else if(this.txt_num==1){
                    this.list = this.oldlist.filter(item=>item.is_end==1);
                }else if(this.txt_num==3){
                    this.list = this.oldlist.filter(item=>item.isWinner==1);
                }else if(this.txt_num==4){
                    this.list = this.oldlist.filter(item=>item.isWinner!=1);
                }
                else{
                    this.list = this.oldlist.filter(item=>item.is_end!=1);
                }
                
                Notify({ type: 'success', message: res.msg });
            }
        })          
      },
      getRecordList(recordShow,recordList){
          this.recordshow = recordShow;
          this.recordList = recordList
      },
      change(repay,item){
          this.repay = repay;
          this.item = item;
      },
      checkDetail(idx){
          this.listData.forEach((item,index)=>{
              if(idx == index){
                  item.typeFlag = !item.typeFlag;
              }
          })
      },
  },
};
</script>
